<template>
  <div class="temp_empty">
    <img src="../assets/img/empy.png" height="150px" alt="" />
    <p class="my-3 font-weight-bold">{{ txt ? txt : "No Data Available" }}</p>
  </div>
</template>

<script>
export default {
  name: "emptyDAta",
  props: ["txt"],
};
</script>

<style scoped>
.temp_empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 200px;
}
</style>
