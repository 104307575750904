import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/css/style.css";
import "./plugins/day";
import Vue2Editor from "vue2-editor";
import VueSnip from "vue-snip";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  timeout: 2000
};

Vue.use(Toast, options);
Vue.use(Vue2Editor);
Vue.use(VueSnip);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
