import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || null, //token
    profile: null,
    getingProfile: true,
    config: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  },
  getters: {
    getUser(state) {
      return state.token !== null && state.token !== undefined;
    },
  },
  mutations: {},
  actions: {
    getProfile(context, data) {
      return new Promise((resolve, reject) => {
        if (data.form == "main") {
          context.state.getingProfile = true;
        }
        let env = process.env.VUE_APP_ENV;
        let config = context.state.config;
        axios
          .get(
            `${env}api/user-view?user_id=${localStorage.getItem("id")}`,
            config,
          )
          .then((res) => {
            context.state.profile = res.data.data;
            setTimeout(() => {
              context.state.getingProfile = false;
            }, 2000);
          })
          .catch((err) => {
            if (err.response.data) {
              reject(err.response.data);
            } else {
              reject(err);
            }
          });
      });
    },
    postApi(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        let config = context.state.config;
        axios
          .post(`${env}api/${data.path}`, data.body, config)
          .then((res) => {
            resolve(res.data ? res.data : res);
          })
          .catch((err) => {
            if (err.response.data) {
              reject(err.response.data);
            } else {
              reject(err);
            }
          });
      });
    },
    getApi(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        let config = context.state.config;
        axios
          .get(`${env}api/${data.path}`, config)
          .then((res) => {
            resolve(res.data ? res.data : res);
          })
          .catch((err) => {
            if (err.response.data) {
              reject(err.response.data);
            } else {
              reject(err);
            }
          });
      });
    },
    putApi(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        let config = context.state.config;
        axios
          .put(`${env}api/${data.path}`, data.body, config)
          .then((res) => {
            resolve(res.data ? res.data : res);
          })
          .catch((err) => {
            if (err.response.data) {
              reject(err.response.data);
            } else {
              reject(err);
            }
          });
      });
    },
    delApi(context, data) {
      return new Promise((resolve, reject) => {
        let env = process.env.VUE_APP_ENV;
        let config = context.state.config;
        axios
          .delete(`${env}api/${data.path}`, config)
          .then((res) => {
            resolve(res.data ? res.data : res);
          })
          .catch((err) => {
            if (err.response.data) {
              reject(err.response.data);
            } else {
              reject(err);
            }
          });
      });
    },
  },
  modules: {},
});
