<template>
  <div class="bg_blue fullheight d-flex align-center relative containe_hero">
    <img src="../../assets/img/stroke_fill.png" class="stroke_filled" alt="" />
    <div class="content_hero" >
      <div class="temp_bg mb-7">
        <img src="../../assets/img/LOGO IPO.png" height="100px" alt="" />
      </div>
      <h1 class="white--text">#IPOuntukINDONESIA</h1>
      <p class="white--text">
        Ikatan Psikolog Olahraga (IPO) merupakan wadah tunggal bagi Psikolog
        Olahraga dan Ilmuwan Psikologi yg berminat Psikologi Olahraga di
        Indonesia. IPO adalah rujukan utama dan unggulan bagi masyarakat perihal
        Psikologi Olahraga dan peningkatan prestasi olahraga tingkat nasional
        dan internasional.
      </p>
      <div style="height: 50px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>

<style></style>
