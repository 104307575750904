<template>
  <div class="container_why">
    <v-row>
      <v-col md="6" cols="12">
        <div class="img_why"></div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="txt_why">
          <h1 class="purple_txt">Mengapa Memilih IPO?</h1>
          <p>
            SDM (kuantitas dan kualitas SDM) berpengalaman dalam menangani atlet
            <br />
            <br />
            Anggota IPO adalah minimum sarjana psikologi, Psikolog, mantan atlet
            …
            <br />
            <br />
            Memiliki Network yang cakupannya luas misalnya KONI, KOI, KEMENPORA,
            TNI dan CABOR, NPC Indonesia, KEMENDIKBUD RISTEK, MEDIA BERITA,
            Perguruan Tinggi, Perusahaan swasta dan BUMN.
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "whiIpo",
};
</script>

<style></style>
