<template>
  <div>
    <navbar />
    <hero-section />
    <misi-page />
    <wyh-ipo />
    <my-mitra />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "./landing/FooterSection.vue";
import HeroSection from "./landing/heroSection.vue";
import MisiPage from "./landing/misiPage.vue";
import MyMitra from "./landing/myMitra.vue";
import WyhIpo from "./landing/wyhIpo.vue";
export default {
  name: "Home",
  components: { HeroSection, MisiPage, WyhIpo, MyMitra, Footer, Navbar },
};
</script>
