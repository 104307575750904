<template>
  <div class="container_mitra">
    <h1 class="purple_txt text-center mb-3">Mitra Kami</h1>
    <div v-if="!loading">
      <v-row v-if="dataSet.length">
        <v-col cols="6" md="3" v-for="(item, i) in dataSet" :key="`i-${i}`">
          <div class="temp_mitra">
            <img :src="item.thumbnail" alt="mitra" class="mitra_img" />
          </div>
        </v-col>
      </v-row>
      <EmptyData v-else />
    </div>
    <v-row v-else>
      <v-col cols="6" md="3" v-for="item in 8" :key="item">
        <!-- <div class="temp_mitra"></div> -->
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmptyData from "@/components/emptyData.vue";
export default {
  name: "pageMitra",
  data() {
    return {
      loading: false,
      page: 1,
      limit: 100,
      last_page: null,
      dataSet: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let data = {
        path: `mitra`,
      };
      this.$store
        .dispatch("getApi", data)
        .then((data) => {
          this.dataSet = data.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  components: { EmptyData },
};
</script>

<style></style>
