import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "",
    component: () => import("../dashboard/core/mainPage.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/dashboard",
        name: "oVERVIEW",
        component: () => import("../dashboard/core/overView.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/profile",
        name: "Profile",
        component: () => import("../dashboard/user/profileDetail.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-user",
        name: "manageUser",
        component: () => import("../dashboard/user/tableUser.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-permohonan",
        name: "managePermohonan",
        component: () => import("../dashboard/permohonan/tablePermohonan.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-article",
        name: "manageArticle",
        component: () => import("../dashboard/article/tableArticle.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/create-article",
        name: "createArticle",
        component: () => import("../dashboard/article/createArticle.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/update-article/:id",
        name: "updateArticle",
        component: () => import("../dashboard/article/updateArticle.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-informasi",
        name: "manageInfo",
        component: () => import("../dashboard/informasi/tableInfo.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/create-info",
        name: "createInfo",
        component: () => import("../dashboard/informasi/createInfo.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/update-info/:id",
        name: "updateInfo",
        component: () => import("../dashboard/informasi/updateInfo"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-misi",
        name: "manageMisi",
        component: () => import("../dashboard/misi/tableMisi.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-mitra",
        name: "manageMitra",
        component: () => import("../dashboard/mitra/tableMitra.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-sejarah",
        name: "manageSejarah",
        component: () => import("../dashboard/sejarah/manageSejarah.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-portofolio",
        name: "managePortofolio",
        component: () => import("../dashboard/portfolio/tablePorto.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/manage-dokumentasi",
        name: "manageDokumentasi",
        component: () =>
          import("../dashboard/dokumentasi/tableDokumentasi.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresVisitor: true },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/sejarah",
    name: "Sejarah",
    component: () => import("../views/fullSejarah.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/program",
    name: "Program",
    component: () => import("../views/ProgramView.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/keanggotaan",
    name: "Keanggotaan",
    component: () => import("../views/KeanggotaanView.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/feeds",
    name: "feeds",
    component: () => import("../views/FeedsView.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/article/:id",
    name: "read article",
    component: () => import("../views/landing/readArticle.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/informasi/:id",
    name: "read informasi",
    component: () => import("../views/landing/readInformation.vue"),
    meta: { requiresVisitor: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../dashboard/auth/Login.vue"),
    meta: { requiresVisitor: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getUser) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.getUser) {
      next({
        path: "/dashboard",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
